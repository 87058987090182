<template>
  <div style="text-align: center;">
    <div style="display: inline-block;">
      <div
        style="margin-top: 8px;font-family: cabin_sketch_bold;font-size: 60px;">
        <span class="choisis_un_mot">{{ $t('sketchy.page_jeu.etat_1.choisis_mot') }}</span>

        <div class="machin_devra_dessiner">
          <span class="swing" v-if="gameOptions.longer_games || nb_joueurs%2 === 0" style="color: #02ba00">
            {{ $t('sketchy.page_jeu.etat_1.personne_dessin_2') }}
          </span>
          <span v-else style="color: #e97500;">
            <template v-if="next_joueur_nom !== null">
              {{ $t('sketchy.page_jeu.etat_1.personne_dessin_1_$s', [next_joueur_nom]) }}
            </template>
            <template v-else>
              {{ $t('sketchy.page_jeu.etat_1.personne_dessin_1') }}
            </template>
          </span>
        </div>
      </div>

      <button
        v-for="(mot, i) in e1_mots"

        class="button is-large"
        :class="{'is-success': mot === motChoisi}"
        style="color: #000;margin-left: 18px;margin-bottom: 10px;font-family: cabin_sketch_regular;font-size: 30px;padding: 14px;display: block"

        @click="choix(i)"
      >
        {{ mot }}
      </button>

      <div class="field has-addons input-message" v-if="gameOptions.custom_words">
        <p class="control is-expanded">
          <textarea
            type="text"
            class="input is-large"
            :class="{'is-success': motCustom === motChoisi}"
            :style="{
              height: textAreaHeight,
              'border-bottom-right-radius': textAreaHeightRaw > 40 ? '4px' : undefined,
            }"
            @input="recalculateHeight"
            :placeholder="$t('sketchy.page_jeu.etat_1.custom_word')"
            v-model="motCustom"
            style="font-family: cabin_sketch_regular;resize: none;overflow: hidden;min-height: 60px;"
            :maxlength="$sketchy_mot_max"
            @keyup.enter="choixCustom"
            @keypress.enter="$event.preventDefault()"
            ref="textArea"
          />
        </p>
        <p class="control">
          <button
            class="button is-large"
            :class="{'is-success': motCustom === motChoisi}"
            style="font-family: cabin_sketch_bold;"
            @click="choixCustom"
          >
            {{ $t('sketchy.page_jeu.etat_1.choose') }}
          </button>
        </p>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/resources/libraries/bulma-0.9.0/sass/utilities/_all.sass';

.choisis_un_mot {
  font-size: 60px;

  @include mobile {
    font-size: 42px;
  }
}

.machin_devra_dessiner {
  font-size: 30px;

  @include mobile {
    font-size: 22px;
  }
}
</style>

<script>
export default {
  name: 'Etat1AttenteMot',

  data() {
    return {
      motChoisi: null,
      motCustom: '',

      textAreaHeightRaw: 5,
      textAreaHeight: '5px',
    }
  },

  methods: {
    recalculateHeight: function () {
      this.textAreaHeight = '5px';
      this.$nextTick(() => {
        this.textAreaHeightRaw = this.$refs.textArea.scrollHeight;
        this.textAreaHeight = this.$refs.textArea.scrollHeight + 'px';
      });
    },

    onMessage(msg_type, msg_data) {
      console.log('[Etat1AttenteMot] reçu', msg_type, msg_data);

      if (msg_type === 'e1_mot') {
        this.motChoisi = msg_data;
        this.motCustom = null;

        for (let i in this.e1_mots) {
          if (this.e1_mots[i] === msg_data) {
            return;
          }
        }
        this.motCustom = msg_data;
      }
    },

    choix(i) {
      this.$emit('sendMsg', 'e1_mot', i);
      this.motChoisi = this.e1_mots[i];
      this.motCustom = null;

      this._matomo_setCustomVariable(3, 'Mot', this.e1_mots[i], 'visit');
      this.$emit('lastMotChoisi', this.e1_mots[i]);
    },

    choixCustom() {
      let motCustom = this.motCustom.trim();
      if (motCustom.length === 0) return;

      this.$emit('sendMsg', 'e1_mot_custom', motCustom);
      this.motChoisi = motCustom;

      this._matomo_setCustomVariable(3, 'Mot custom', motCustom, 'visit');
    },
  },

  props: [
    'e1_mots', 'nb_joueurs', 'next_joueur_nom', 'gameOptions',
  ],
}
</script>
